 

 .dispositivos{
    justify-content: center;
    /* display: ; */
 }

 .selectores{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 1rem;
 }

.buttonsElements{
    justify-content: center;
    display: flex;

    text-align: center;
    margin-top: 1%;
    margin-bottom: 2%;
    /* width : fit-content; */
} 
 .buttonsElements ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333333;
  }
  
  .buttonsElements ul li {
    float: left;
    cursor: pointer;
  }
  
  .buttonsElements ul li a {
    display: block;
    color: white !important;
    text-align: center;
    padding: 16px;
    text-decoration: none;
  }
  
   
  li a:hover {
    /* background-color: #111111; */
  }

  .button {
    display: block;
    margin: 0 auto;
    margin-top: 5px;
    border-radius: 3px;
    padding: 10px;
    background-color: #dc3545;
  }

