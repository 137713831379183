.busqueda{
position: relative;
left: 0%;
right: 0%;
top: 0%;
bottom: 0%;

/* c4 */

background: #EFEFEF;
/* sombra */

box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
border-radius: 6px;}


.texto-busqueda{
    /* Búsqueda */


/* position: relative; */
/* left: 10.2%; */
/* right: 73.98%; */
top: 32.5%;
bottom: 27.5%;

/* descripción */

font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 10%;
/* identical to box height, or 16px */


/* text */

color: var(--text-color-1);

}