.card{
    border: var(--primary-color);
}



h3{
    text-align: center;
}


.imagenGPF{
    width: 100%;
}

    /* background-color: aqua; */
    /* background-image: url("../../assets/img/gpf/fondo-nave.jpg") ; */
    /* background-image: url("../../assets/img/distribucion-01.png") ; */

.svgComponent{

    
    background-size: 100% 100%;
}


.horaReporte{
    font-size: 24px !important;
}

.estiloModal{
    width: 50%;
}

.margin{
    margin-top: 3%;
}