.inputborde{

/* 
position: absolute;
left: 0%;
right: 0%;
top: 0%;
bottom: 0%; */

/* Gray 3 */

border: 0.75px solid #a09999 !important;
border-radius: 5px;


}

.inputerror{
    position: absolute;
left: 0%;
right: 0%;
top: 0%;
bottom: 0%;

/* a5 */

border: 0.75px solid #FF2942;
border-radius: 5px;
}


.estilo-inputText{
    border: 0.75px solid #828282;
    border-radius: 5px;
  }