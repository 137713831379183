.busqueda  {
    float: left;
    cursor: pointer;
    padding: 12px;
    width: 45%;
    
    
  }
  .registro {
    float: right ;
    cursor: pointer;
    padding: 5px;
    width: 25%;
  }