.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button {
  display: block;
  margin: 0 auto;
  margin-top: 50px;
  border-radius: 6px;
  padding: 20px;
}

.modal-background {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(10, 10, 10, 0.86);
}

.modal-card {
  margin: 0 auto;
  display: block;
  margin-top: 250px;
  width: 300px;
  height: 300px;
  background-color: lightgray;
  border-radius: 5px;
}

.uno {
  text-align: center;
  background-color: aliceblue;
  color: rgb(0, 0, 0);
}
.boton {
  margin: 10px;
  padding: 10px;
  border-radius: 4px;
  transition-duration: 0.4s;
  background-color: #c9cacb;

}

.boton:hover {
  background-color:cadetblue;
  color: rgb(255, 255, 255);
}