.botonN{
  background-color: #114396;
  color: white;
  /* font-family: Montserrat;
  font-style: normal; */
  font-weight: 400;
  font-size: 16px;
  padding: 10px 60px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
}
.botonN:hover {
  background-color:#0A1F45;
  color: rgb(255, 255, 255);
}

.boton1N{
  background-color: #0467D9;
  color: white;
  /* font-family: Montserrat;
  font-style: normal; */
  font-weight: 400;
  font-size: 16px;
  padding: 10px 60px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
}
.boton1N:hover {
  background-color:#0A1F45;
  color: rgb(255, 255, 255);
}

.boton2N{
  background-color:#BDBDBD;
  color: white;
  /* font-family: Montserrat;
  font-style: normal; */
  font-weight: 400;
  font-size: 16px;
  padding: 10px 60px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
}
.boton2N:hover {
  background-color:#70747a;
  color: rgb(255, 255, 255);
}

.bordeDiv{
  border-bottom-style: solid;
  border-color: #CECFC9;
}

.inicioIzq{
  color: #4F4F4F;
  width: 250px;
}

.selector{
  display: block;
  width: 100%;
  padding: .375rem 2.25rem .375rem .75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background-color: #114396;
  background-image: url("down1.png");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

.lista{
  text-align: left;
  font: menu;
  font-size: 11pt;
  background-color: #EFEFEF;
  margin: 10px;
  padding: 10px;
  width: auto !important;
  height: 65vh;
  border-radius: 4px;
  float:left;
  overflow-y: scroll;
}

.lista2{
  text-align: left;
  font: menu;
  font-size: 11pt;
  background-color: #82BFFF;
  margin: 10px;
  padding: 10px;
  width: 200px;
  height:auto;
  border-radius: 4px;
  float:left;
}

.bordeDivLista{
  border-bottom-style: solid;
  border-color: #CECFC9;
  border-bottom-width: thin;
  margin: 10px;
}

.bordeDivLista2{
  border-bottom-style: solid;
  border-color: #fff;
  border-bottom-width: thin;
  margin: 10px;
}

.checkmark {
  /* position: absolute; */
  top: 0;
  left: 0;
  height: 13px;
  width: 13px;
  background-color: #eee;
  margin-bottom: 8px;
}

.checkValues{
  padding-left: 8px;
}