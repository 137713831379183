


.notification-dropdown{
    display: inline-flex !important;
    /* justify-content: flex-end; */
    margin-right: 0%;
}



.dropdown-menu li a{
    /* display: flex; */
    margin-right: 5%;
    margin-left: 5%;
    margin-top: 3%;
    margin-bottom: 3%;
    background-color: var(--auxiliary1-background-color);
}


.notification-dropdown ul{
    border-radius: 15px;
}

.titulo-notificaciones{
    margin: 0px;
}

.subtitulo-notificaciones{  
    margin: 0px;
    display: flex;
    justify-content: flex-end;
    margin-right: 4%;
}

.d-flex div{
    width: 100%;
}

.media-body{
    width: 100%;

}

.scrollable-container{
    overflow-y: scroll;
    height: 80vh;
}


.configuracion-perfil{
    margin: 20%;
}


.dropdown-menu-items{
    min-width: 20rem !important;
}


.notificacion-sub{
    display:flex;
    justify-content: space-between;
    align-items: center;
}