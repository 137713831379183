.Cuadro {
  text-align: left;
  font: menu;
  font-size: 11pt;
  background-color: #c9cacb
  ;
  margin: 10px;
  padding: 10px;
  width: 200px;
  height:auto;
  border-radius: 4px;
  float:left;
  
}

.Cuadro2 {
  text-align: left;
  font: menu;
  font-size: 11pt;
  background-color: #e99298
  ;
  margin: 10px;
  padding: 10px;
  width: 200px;
  height:auto;
  border-radius: 4px;
  float:left;
}

.Tabla {
  text-align: center;
  font: menu;
  background-color: white;
  font-size: 11pt;
}

.Modal1{
  margin:auto;
  background-color:transparent;
  position:relative;
  padding:0;
  outline:0;
  width:600px
}

.leaflet-container {
  height: 420px;
  width: 640px;
}

.centrar {
  text-align: center
}





/* lista de dispositivos  */

.dispositivos{
  display: block;


}