.busqueda  {
    float: left;
    /* cursor: pointer; */
    padding: 3%;
    max-width: 30%;
    
    
  }


  .buttonGeneral{
    background-color: var(--primary-color) !important;
    /* color: var(--primary-color); */
  }

  .button-secondary{
    background-color: var(--primary-background-color) !important;
    /* color: var(--primary-color); */
  }