




.sidebar{
    width: 250px;
    max-width: 250px;


}


.logo-img{
    width: 90%
}


.sidebar .nav {

    background-color: var(--primary-background-color);
    display: block; 

}


.sidebar .nav {

    /* background-color: #8e8e8e; */
    display: block; 

}

.sidebar{

    background-color: var(--primary-background-color);
    /* display: block;  */

}

.textoNavlinks{
    color: white;

}