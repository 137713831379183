 






 .dispositivos{
    justify-content: center;
    /* display: ; */
 }

 .selectores{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 1rem;
 }

.buttonsElements-cadenaFrio{
    justify-content: center;
    display: flex;

    text-align: center;
    margin-top: 1%;
    margin-bottom: 2%;
    /* width : fit-content; */
} 
 .buttonsElements-cadenaFrio ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #BDBDBD;
  }
  
  .buttonsElements-cadenaFrio ul li {
    float: left;
    cursor: pointer;
  }
  
  .buttonsElements-cadenaFrio ul li a {
    display: block;
    color: white !important;
    text-align: center;
    padding: 16px;
    text-decoration: none;
  }
   
   
  .buttonsElements-cadenaFrio ul li a:hover {
    background-color: var(--primary-color);
  }



  .body-card{
    height: 40vh;
  }
  

  .estado-boton-activo{
    background-color: var(--primary-color);
  }


  .fila-tabla{
    cursor: pointer;
  }