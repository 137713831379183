

/* // Your variable overrides */
// $sidebar-bg-color: #1d1d1d;



// $sidebar-bg-color: #1d1d1d !default;
$sidebar-color: #adadad !default;
$sidebar-width: 270px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: #d8d8d8 !default;
// $submenu-bg-color: green !default;
$submenu-bg-color-collapsed: var(--primary-color) !default;
$icon-bg-color: var(--auxiliary1-background-color) !default;
$icon-size: 35px !default;
$submenu-indent: 24px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
$breakpoint-xxl: 1600px !default;


@import '~react-pro-sidebar/dist/scss/styles.scss';




.sidebar-style{
    color: var(--text-color-1);

    

}

.logo-aiot{
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    
}

.sidebar-style div{
    background-color: var(--secondary-background-color);

}

/* .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content */

.styleMenuItem{
    //  color: tomato; 
    font-size: small !important;
}

.submenuStyle .pro-inner-list-item{
    background-color: var(--secondary-background-color) !important;
 
    
    .popper-inner {

        ul{
            width: 80%;
            margin-left: auto;
            margin-right: auto;
        }

    }

}

/* ////////////////////////////////////////////////////////////////////////////////////////////// */


.sideBarMenuContainer {
    position: fixed;
    height: 100%;
}

.userMenu {
    width: 100%;
    text-align: center;
    margin-right: 0px;
    margin-left: 0px;    
    margin-top: 0px;
}

nav.sidebar :global(.navbar-brand) :global(.glyphicon) {
    margin-right: 0px;
}


/*Remove rounded coners*/
nav.sidebar :global(navbar) {
    border-radius: 0px;
}

nav.sidebar {
    -webkit-transition: margin 200ms ease-out;
    -moz-transition: margin 200ms ease-out;
    -o-transition: margin 200ms ease-out;
    transition: margin 200ms ease-out;
}

/* .....NavBar: Icon only with coloring/layout.....*/

/*small/medium side display*/
@media ( min-width : 768px) {


    /*Center Brand*/
    nav.sidebar :global(navbar).sidebar>.container :global(.navbar-brand), :global(.navbar)>:global(.container-fluid) :global(.navbar-brand)
        {
        margin-left: 0px;
    }
    /*Center Brand*/
    nav.sidebar :global(.navbar-brand), nav.sidebar :global(.navbar-header) {
        text-align: center;
        width: 100%;
        margin-left: 0px;
    }

    /*Center Icons*/
    nav.sidebar a {
        padding-right: 13px;
    }

    /*adds border top to first nav box */
    nav.sidebar :global(.navbar-nav)>li:first-child {
        border-top: 1px #e5e5e5 solid;
    }

    /*adds border to bottom nav boxes*/
    nav.sidebar :global(.navbar-nav)>li {
        border-bottom: 1px #e5e5e5 solid;
    }

    /* Colors/style dropdown box*/
    nav.sidebar :global(.navbar-nav) :global(.open) :global(.dropdown-menu) {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    /*allows nav box to use 100% width*/
    nav.sidebar :global(.navbar-collapse), nav.sidebar :global(.container-fluid) {
        padding: 0 0px 0 0px;
    }

    /*colors dropdown box text */
    :global(.navbar-inverse) :global(.navbar-nav) :global(.open) :global(.dropdown-menu)>li>a {
        color: #777;
    }

    /*gives sidebar width/height*/
    nav.sidebar {
        width: 200px;
        height: 100%;
        margin-left: -160px;
        float: left;
        z-index: 8000;
        margin-bottom: 0px;
    }

    /*give sidebar 100% width;*/
    nav.sidebar li {
        width: 100%;
    }

    /* Move nav to full on mouse over*/
    nav.sidebar:hover {
        margin-left: 0px;
    }
    /*for hiden things when navbar hidden*/
    :global(.forAnimate) {
        opacity: 0;
    }
}

/* .....NavBar: Fully showing nav bar..... */
@media ( min-width : 1330px) {

    /*Show all nav*/
    nav.sidebar {
        margin-left: 0px;
        float: left;
    }
    /*Show hidden items on nav*/
    nav.sidebar :global(.forAnimate) {
        opacity: 1;
    }
}

nav.sidebar :global(.navbar-nav) :global(.open) :global(.dropdown-menu)>li>a:hover, nav.sidebar :global(.navbar-nav) :global(.open) :global(.dropdown-menu)>li>a:focus
    {
    color: #CCC;
    background-color: transparent;
}

nav:hover :global(.forAnimate) {
    opacity: 1;
}

section {
    padding-left: 15px;
}