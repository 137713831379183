


.formulario{
    width: 300px;
}

.formulario label{
    width: 200px;

}


.formulario input{
    width: 200px;
}

.div-botones{
    margin-top: 3%;
    margin-bottom: 3%;
}


.button-edicion{
    align-items: center;
    text-align: center;

}

.button-edicion button{
    width: 80%;
}



.modulos{
    display: flex;
    align-items: center;
}