.inputBox {
        width: 30%;
        padding: 2em;
        text-align: center;
        background-color: white;
        border-radius: .3em;
    }

    .inputBox h3 {
        font-size: 2em;
        font-weight: 300;
    }

    .inputBox input[type="text"] {
        width: 100%;
        padding: .5em;
        margin: 1em 0;
        border-radius: .3em;
        border: 1px solid grey;
        box-sizing: border-box;
    }

    .inputBox input[type="number"] {
        padding: .5em;
        border-radius: .3em;
        border: 1px solid grey;
        box-sizing: border-box;
        width: 100%;
    }

    label {
        display: block;
        text-align: left;
    }

    .flex {
        display: flex;
        flex-direction: row;

    }

    .inputNumber {
        width: 50%;
    }

    .second {
        margin-left: 5%;
    }

    .boton {
      margin: 10px;
      padding: 10px;
      border-radius: 4px;
      transition-duration: 0.4s;
      background-color: #c9cacb;
    
    }

    .boton2 {
      padding: 2px;
      border-radius: 1px;
      transition-duration: 0.4s;
      background-color: #c9cacb;
      font-size: small
    }
    
    .boton2:hover {
      background-color:cadetblue;
      color: rgb(255, 255, 255);
    }