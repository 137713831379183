.menu{
    text-align: center;
    justify-content: center;
    margin-top: 10vh;
    min-width: 35%;
}

/* .login-wrapper{
    display: flex;
    justify-content: center;
} */

.wrapper{
    background-color: var(--primary-background-color);
    min-height: 95vh;
}

.main-panel{
    margin: 3vh;
    background-color: var(--secondary-background-color);
    width: 100%;
    height: auto;
    border-radius: 10px;
    
}

/*.main-panel{
    background-color: chocolate;
    height: auto;
    min-height: 90vh;
  }

  */