.busqueda  {
    float: left;
    cursor: pointer;
    padding: 12px;
    width: 45%;
    
    
  }


.estiloModal2 .modal-dialog{
    max-width: 60%;

  }

.bloqueTexto{
  height: 25px;
}

.estiloMapa{
  width: 100%;

}

.map{
  
  position: relative !important;
  height: 400px !important;

}