.texto{
 text-align: center;
}

.trAncho{
  width: 10%;
}

.tdAncho{
  width: 10%;
}

.cuadro{
  width: 100%;
}

.tabla1{
  display: block !important;
  overflow-x: auto !important;
  width: 100% !important;
}