 

 .buttonVinculacion{
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    /* display: ; */
 }


 .cursor-mano{
    cursor:pointer
 }