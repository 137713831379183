.login-panel{
    text-align: center;
    justify-content: center;
    margin-top: 10vh;
    min-width: 35%;
}

.login-wrapper{
    display: flex;
    justify-content: center;
}