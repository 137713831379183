.text-tabla{
    /* org */

    vertical-align: middle;

/* position: relative; */
width: 135.2px;
height: 18px;
/* left: 278px; */
/* top: 11px; */

/* acento cuerpo */

font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 130%;
/* or 18px */


/* c2 */

color: #114396;
}

.tabla{
position: relative;
left: 0.03%;
right: 0.06%;
top: 11.66%;
bottom: 0.11%;

background: #FFFFFF;
/* sombra */

box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
border-radius: 5px;
}
.texto-titulo{

    /* Organización */


/* position: relative; */
left: 25.01%;
right: 61.36%;
top: 21.49%;
bottom: 76.46%;

/* acento cuerpo */

font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 130%;
/* or 18px */


/* text */

color: #4F4F4F;
}

.cuadro-tabla{
       /* Rectangle 57 */


position: relative;
left: 0.03%;
right: 0.06%;
top: 19.77%;
bottom: 75.08%;

/* c4 */

background: #EFEFEF;
}